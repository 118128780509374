/* eslint import/no-extraneous-dependencies: "off" */

// import node_modules
import React from 'react';

// import components
import Layout from '../components/Layout';

// declare custom component
const Impressum = () => (
  <Layout
    description="Legal notice"
    image="/img/silke-blume-hero-background.png"
    pathname="/legal-notice/"
    title="Legal notice | Silke Blume"
  >
    <section id="impressum" className="is-text">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters-tablet is-two-thirds-desktop is-three-fifths-widescreen is-half-fullhd">
            <h1 className="title is-size-2">Impressum</h1>

            <h5 className="is-size-5 is-padding-top-1">Angaben gemäß § 5 TMG:</h5>
            <p>
              ICA In Crowd UG
              <br />
              Hochallee 118
              <br />
              20149 Hamburg
            </p>

            <h5 className="is-size-5 is-padding-top-1">Vertreten durch:</h5>
            <p>Silke Blume</p>

            <h5 className="is-size-5 is-padding-top-1">Kontakt:</h5>
            <p>
              Telefon: +491625744443
              <br />
              E-Mail: silke.blume@incrowdassets.com
              <br />
              Website: www.ica-incrowd.com
            </p>

            <h5 className="is-size-5 is-padding-top-1">Registereintrag:</h5>
            <p>
              Eintragung im Handelsregister
              <br />
              Registernummer: 148313
              <br />
              Registergericht: Amtsgericht Hamburg
              <br />
              Steuernummer: 42/733/00991
            </p>

            <h5 className="is-size-5 is-padding-top-1">Kapital:</h5>
            <p>Stamm- oder Grundkapital: 500,00 €</p>

            <h5 className="is-size-5 is-padding-top-1">Umsatzsteuer-ID:</h5>
            <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: beantragt</p>

            <h5 className="is-size-5 is-padding-top-1">
              Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
            </h5>
            <p>Silke Blume</p>

            <h5 className="is-size-5 is-padding-top-1">
              Quellenangaben für die verwendeten Bilder und Grafiken:
            </h5>
            <p>ICA In Crowd UG, Hochallee 118, 20149 Hamburg</p>

            <h5 className="is-size-5 is-padding-top-1">Haftung für Inhalte:</h5>
            <p>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
              Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
              Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
              rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
              Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
              Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
              konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>

            <h5 className="is-size-5 is-padding-top-1">Haftung für Links:</h5>
            <p>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir
              keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
              übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
              der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
              Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
              verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
              entfernen.
            </p>

            <h5 className="is-size-5 is-padding-top-1">Urheberrecht:</h5>
            <p>
              Die durch die Seitenbetreiber erstellten bzw. verwendeten Inhalte und Werke auf diesen
              Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
              Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
              bedürfen der Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
              dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit
              die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
              gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
              werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
            <br />
            <p>
              Quelle:{' '}
              <a href="https://www.twigg.de/haftungsausschlussimpressumenglisch.htm">
                twiggs translations
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Impressum;
